<template>
  <div>
    <!-- #region Form -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3"> {{$t("questionEditPage.pageTitle")}}</strong>
          </CCardHeader>
          <CCardBody>

            <span class="caption">
              {{ $t("common.editCaption") }}
            </span>
            <CForm class="submit_form">
              <!-- #region 質問 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{$t("questionEditPage.name")}}</strong></CCol>
                <CCol>
                  <CInput v-model="editJa.name" />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.editJa.name.$dirty &&
                      !$v.editJa.name.required
                    "
                  >
                    {{ $t("validations.required", { vName: $t("common.name")})}}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.editJa.name.$dirty &&
                      !$v.editJa.name.maxLength
                    "
                  >
                    {{ $t("validations.maxLength", { vName: $t("common.name"),vMax: $v.editJa.name.$params.maxLength.max })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 質問 -->

              <!-- #region 説明文 -->
              <CRow>
                <CCol class="label"><strong>{{$t("questionEditPage.description")}}</strong></CCol>
                <CCol>
                  <CTextarea
                    class="mb-2"
                    v-model="editJa.description"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.editJa.description.$dirty &&
                      !$v.editJa.description.maxLength
                    "
                  >
                    {{ $t("validations.maxLength", { vName: $t("common.description"),vMax: $v.editJa.description.$params.maxLength.max })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 説明文 -->

              <!-- #region 回答方法 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{$t("questionEditPage.answerMethod")}}</strong></CCol>
                <CCol>
                  <CInputRadioGroup
                    :options="answerMethods"
                    :checked.sync="editJa.answerMethod"
                  />
                  <!-- TODO:選択してもバリデーションの文が消えない -->
                  <!-- <p
                    class="text-danger input-info"
                    v-if="
                      $v.editJa.answerMethods.$dirty &&
                      !$v.editJa.answerMethods.required
                    "
                  >
                    回答方法を選択してください。
                  </p> -->
                </CCol>
              </CRow>
              <!-- #endregion 回答方法 -->

              <!-- #region 選択肢 -->
              <CRow v-if="editJa.answerMethod === `select`">
                <CCol class="label"><strong class="required-mark">{{$t("questionEditPage.choices")}}</strong></CCol>
                <CCol
                >
                <CButton
                  color="info"
                  size="sm"
                  class="m-2"
                  @click="onAddInputClicked()"
                ><i class="icon cil-plus"></i>{{$t("questionEditPage.addChoicesButton")}}</CButton>
                <div
                  v-for="(item,i) in inputObjects"
                  :key=i
                >
                  <CInput
                    v-if="i!== 0"
                    class="w-75 float-left"
                    v-model="editJa.choices[i]"
                  />
                  <!-- TODO:バリデーションが表示されていないのでなおすこと -->
                  <!-- <p
                    class="text-danger input-info"
                    v-if="$v.editJa.choices.$dirty && !$v.editJa.choices.maxLength"
                  >
                    {{ $t("validations.maxLength", { vName: $t("common.name"),vMax: $v.editJa.choices[i].$params.maxLength.max })}}
                  </p> -->
                  <CButton
                    v-if="i!== 0"
                    color="danger"
                    size="sm"
                    class="m-2"
                    @click="onDeleteInputClicked(editJa.choices[i])"
                  ><i class="icon cil-trash"></i> {{$t('common.delete')}}</CButton>
               
                </div>
                </CCol>
              </CRow>
              <!-- #endregion 選択肢 -->

              <!-- #region 入力例text -->
              <CRow v-if="editJa.answerMethod === `text`">
                <CCol class="label"><strong>{{$t("questionEditPage.inputExample")}}</strong></CCol>
                <CCol>
                  <CInput
                    v-if="editJa.answerMethod === `text`"
                    class="mb-2"
                    v-model="editJa.inputExample"
                  />
                </CCol>
              </CRow>
              <!-- #endregion 入力例text -->

              <!-- #region 入力例textarea -->
              <CRow v-if="editJa.answerMethod === `textarea`">
                <CCol class="label"><strong>{{$t("questionEditPage.inputExample")}}</strong></CCol>
                <CCol>
                  <CTextarea
                    class="mb-2"
                    v-model="editJa.inputExample"
                  />
                </CCol>
              </CRow>
              <!-- #endregion 入力例textarea -->

              <!-- #endregion 入力フィールド -->
            </CForm>
          </CCardBody>

          <!-- #region プレビュー -->
          <CCardBody>
            <div 
              class="mt-2"
              v-if="editJa.answerMethod">
              <CRow>
                <CCol>
                  <strong class="card-title mr-3"> {{$t("questionEditPage.preview")}}</strong>
                  <p>{{$t("questionEditPage.previewGuide")}}</p>
                </CCol>
              </CRow>
              <CRow>
                <CCol class="border" >
                    <h4 v-html="editJa.name"></h4>
                    <p v-html="editJa.description"></p>
                    <CSelect
                    v-if="editJa.answerMethod === `select`"
                      class="w-25 mb-2"
                      :options="editJa.choices"
                    />
                    <CInput
                      v-if="editJa.answerMethod === `text`"
                      class="mb-2"
                      v-model="editJa.inputExample"
                    />
                    <CTextarea
                      v-if="editJa.answerMethod === `textarea`"
                      class="mb-2"
                      v-model="editJa.inputExample"
                    />
                </CCol>
              </CRow>
            </div>
            
            <!-- #region 更新ボタン -->
            <CButton
              class="px-5 mt-4 float-left"
              color="info"
              @click="onUpdateClicked()"
              v-bind:class="activeStatus"
            >
              <i class="cil-task"></i> {{ $t("common.update") }}
            </CButton>
            <!-- #endregion 更新ボタン -->

            <!-- #region 削除ボタン -->
            <CButton
              class="px-5 mt-4 float-right"
              color="danger"
              @click="onDeleteClicked()"
            >
              <i class="cil-trash"></i> {{ $t("common.delete") }}
            </CButton>
            <!-- #endregion 削除ボタン -->
          </CCardBody>
          <!-- #endregion プレビュー -->

          <CCardFooter>
            <CButton color="secondary" class="" @click="onBackClicked()"
              ><i class="icon cil-arrow-left mr-2"></i
              >{{ $t("common.back") }}</CButton
            >
          </CCardFooter>

        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Form -->

    <DeleteConfirmModal
      :deleteConfirmModal="deleteConfirmModal"
      :callback="reqDelete"
      @close="deleteConfirmModal = $event"
    />

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
      :car=[]
    />

    <SuccessModal
      :successModal="deleteSuccessModal"
      @close="deleteSuccessModal = $event"
      :car=[]
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />

  </div>
</template>

<script>
import i18n from "@/i18n" 
import axios from "axios";
import Question from "./List";

import {
  required,
  // numeric,
  // minLength,
  maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  name: "QuestionEdit",


  computed: {
    activeStatus() {
      return this.$v.editJa.$invalid ? "inactive" : "active";
    },

    showSuccessModal() {
      return this.successModal || this.deleteSuccessModal;
    },

    uid(){
      return this.$store.state.uniqueId;
    },
  },

  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },

    deleteSuccessModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },

  data() {
    return {
      //#region Flag
      loading: false,
      checkModal: false,
      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,
      deleteSuccessModal: false,
      //#endregion Flag
      questionId:this.$route.params.questionId,
      increment:1,

      // getType:[],

      //#region Message
      errorMessage: "error",
      //#endregion Message

      inputObjects:[0],

      answerMethods: [
        { value: 'select', label: `${i18n.t("questionEditPage.method.select")}`},
        { value: 'text', label: `${i18n.t("questionEditPage.method.text")}`},
        { value: 'textarea', label: `${i18n.t("questionEditPage.method.textarea")}`},
      ],

      //#region request
      // 日本語入力データ
      editJa: {
        language: "ja",
        name: "",
        description: "",
        answerMethod:"",
        choices:["選択してください"],
        inputExample:""
      },


      // editZh:{
      //   language: "zh",
      //   name: "",
      //   description: ""
      // },
      // editEn:{
      //   language: "en",
      //   name: "",
      //   description: ""
      // },

      requests:[],
      //#endregion request

      //#region Response data
      response: [],
      reponseObj:{},
      //#endregion Response data

      isDisplayEnabled: [
        {
          value: false,
          label: `${i18n.t("mediaKindEditPage.isDisplayEnabled.disable")}`,
        },
        {
          value: true,
          label: `${i18n.t("mediaKindEditPage.isDisplayEnabled.enable")}`,
        },
        
      ],
    };
  },

  validations() {
    return {
      editJa: {
        name: {
          required,
          maxLength: maxLength(50),
        },
        description: {
          maxLength: maxLength(1000),
        },
        // //TODO:バリデーションが上手く動いてない
        // answerMethods:{
        //   required
        // },
        // //TODO:バリデーションが上手く動いてない
        // choices:{
        //   maxLength: maxLength(10),
        // },
      },
    };
  },

  methods: {

    //#region Event

    /**入力欄追加クリック */
    onAddInputClicked(){
      this.addInput();
    },

    /**入力欄削除クリック */
    onDeleteInputClicked(string){
      this.deleteInput(string);
    },

    /** 削除ボタン押下 */
    onDeleteClicked() {
      this.deleteConfirmModal = true;
    },

    /** 更新ボタン押下 */
    onUpdateClicked() {
      this.$v.editJa.$invalid ?
        this.$v.editJa.$touch() :
        this.reqPut();
    },

    //#endregion Event

    //言語変換
    // languageConvert(datas){
    //   datas.forEach(element => {
    //     if(element.language === this.$store.state.currentLanguage){
    //       this.editJa.language = element.language;
    //       this.editJa.name = element.name;
    //       this.editJa.description = element.description;
    //     }
    //   });
    // },


    /**取得データセット */ 
    dataInsert(data){
      // NOTE:多言語用の画面でないため、日本語データだけ挿入
      if(data.language === `ja`){
          this.editJa.language = data.language;
          this.editJa.name = data.questionText;
          this.editJa.description = data.description;
        }
    },

    //#region Request

    /** 取得 */
    reqGet() {
      const url = `${Question.url}/${this.uid}/question/${this.questionId}`;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            // "Accept-Language": this.$i18n.locale
            // NOTE:リスト型で取得するために*で言語指定
            "Accept-Language": `*`
          },
        })
        .then((resp) => {
          // const datas = resp.data;
          const data = resp.data;
          this.response = data;
          console.log(data);

          this.dataInsert(this.response)

          // this.languageConvert(this.response);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    /** 更新 */
    reqPut() {
      const url = `${Question.url}/${this.questionId}`;
      // リクエスト初期化
      this.requests =[];
      // リクエストデータのリストにオブジェクト追加
      this.requests.push(this.editJa)
      const body = this.requests;
      console.log(body);

      axios
        .put(url, body, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            // "Accept-Language": this.$i18n.locale
            // NOTE:リスト型で取得するために*で言語指定
            "Accept-Language": `*`
          },
        })
        .then((resp) => {
          console.log("resp.data" + JSON.stringify(resp.data));
          this.successModal = true;
        })
        .catch((err) => {
          console.log(err);
          this.errorMessage = `${err.name} : ${err.message}`;
          this.errorModal = true;
        });
    },

    /**削除 */
    reqDelete() {
     this.loading = true;
     this.editJa = {}
     const url = `${Question.url}/${this.uid}/question/${this.questionId}`;

      axios
        .delete(url, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            // "Accept-Language": this.$i18n.locale
            // NOTE:リスト型で取得するために*で言語指定
            "Accept-Language": `*`
          },
        })
        .then((resp) => {
          console.log(resp.data);
          
          this.deleteConfirmModal = false;
          this.deleteSuccessModal = true;
          // this.languageConvert(this.response);
          this.loading = false;
          
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    //#endregion Request


    //#region Method

    /**入力欄追加 */
    addInput(){

      const id = this.increment;

      this.increment++;

      // this.inputObjects.push({id:id})
      this.inputObjects.push(id)
      
      console.log(this.increment)
    },

    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    backPage() {
      this.$router.push({
        path: "/operator/question/list"
      });
    },

    /**入力欄削除 */
    deleteInput(string){
      const  index = this.editJa.choices.indexOf(string)
      if (index > 0) {
        console.log("delete")
        // プレビューのselectboxの中身削除
        this.editJa.choices.splice(index, 1);
        // 選択肢入力フォームを削除
        this.inputObjects.splice(index,1);
      }
    }

    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>